import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import img1 from '../../assets/carousel/img6.jpeg';
// import img2 from '../../assets/carousel/img2.jpeg';
import img3 from '../../assets/carousel/img002.jpeg';
import img4 from '../../assets/carousel/img12.jpeg';
import img003 from '../../assets/carousel/img003.jpeg';
import img7 from '../../assets/carousel/img7.jpeg';
import img10 from '../../assets/carousel/img10.jpeg';

const responsiveImageHero = {
    desktop: {
        breakpoint: { max: 3400, min: 1088 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const images = [img1, img003, img4, img3, img7, img10];

const CarouselContainer = () => {
    return (
        <Carousel
            showDots={false} // Remove the dot indicators
            infinite
            containerClass="carouselContainer"
            slidesToSlide={1}
            
            responsive={responsiveImageHero}
            autoPlay={true} // Automatically change slides
            autoPlaySpeed={5000} // Change slides every 5 seconds
            removeArrowOnDeviceType={['tablet', 'mobile']} // Remove arrows on tablet and mobile devices
        >
            {images.map((image, id) => {
                return (
                    <img
                        key={id}
                        draggable={true}
                        src={image}
                        style={{
                            maxWidth: '100%',
                            height: 'auto', // Allow the height to adjust according to the aspect ratio
                            maxHeight: '90vh', // Limit height to 90% of the viewport height
                            display: 'block',
                            margin: '0 auto',
                            objectFit: 'cover',
                        }}
                        alt={'img'}
                    />
                );
            })}
        </Carousel>
    );
};

export default CarouselContainer;
