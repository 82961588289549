import React from 'react'
import About from '../../components/about/About';
import CarouselContainer from '../../components/carousel/CarouselContainer';
import Services from '../../components/services/Services';

const HomePage = () => {
    return (
        <div>
            <CarouselContainer style={{ marginBottom: '20px' }} /> {/* Add inline style for margin */}
            
            <About />
            <Services />
        </div>
    )
}

export default HomePage;
