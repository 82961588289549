import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import img001 from '../../assets/carousel/img001.jpeg';
import fance from '../../assets/carousel/fance.jpg';
import img00 from '../../assets/carousel/img00.jpeg';
import img006 from '../../assets/carousel/img006.jpeg';
import ups from '../../assets/carousel/ups.jpg';
import pc from '../../assets/carousel/pc.jpg';
import arlam from '../../assets/carousel/arlam.jpg';

import cover_img from '../../assets/services/img7.jpeg'; 

const ServicesPage = () => {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [img001, fance,pc,arlam, img006, ups];
    const services = [
        {
            title: "CCTV Installation",
            description: "CCTV Installation offers a paramount solution for fortifying security measures within residential and commercial spaces alike. By strategically situating surveillance cameras, this installation enhances vigilance over premises, deterring potential threats and promoting a sense of safety among occupants. It serves as an ever-watchful eye, capturing and recording activities around the clock, thereby facilitating proactive monitoring and timely intervention when necessary. With professional installation and customization to suit specific needs, CCTV systems provide not only peace of mind but also invaluable evidence in the event of security breaches or incidents. Integrating seamlessly with other security measures, CCTV installations represent a crucial component in comprehensive security strategies, ensuring robust protection and safeguarding properties and individuals alike.",
            images: 'img01'
        },
        {
            title: "Electric Fence Installation",
            description: " Electric Fence Installation establishes a formidable barrier around properties, acting as a potent deterrent against unauthorized access and intrusions. By harnessing electric currents, these fences create a formidable obstacle, enhancing perimeter security and fortifying boundaries. This installation not only prevents unauthorized entry but also serves as an early warning system, alerting property owners to any breach attempts. With meticulous installation and integration with existing security infrastructure, electric fences offer a proactive defense mechanism, providing peace of mind and safeguarding assets against potential threats. Additionally, they can be customized to suit specific property layouts and security requirements, ensuring optimal protection and reinforcing overall security measures",
            images: 'img003'
        },
        {
            title: "PC Repair",
            description: "PC Repair services cater to the maintenance and restoration of computer systems, ensuring their optimal functionality and performance. Whether addressing hardware malfunctions, software glitches, or system errors, PC repair technicians diagnose and resolve issues promptly and effectively. From troubleshooting hardware components such as motherboards, CPUs, and RAM modules to troubleshooting software issues like operating system errors or malware infections, PC repair services encompass a comprehensive range of technical expertise. By offering timely repairs and upgrades, these services not only restore computers to working condition but also prolong their lifespan, maximizing productivity and minimizing downtime for users. With a focus on customer satisfaction and technical proficiency, PC repair services provide essential support for individuals and businesses reliant on their computer systems for daily operations.",
            images: 'pc'
        },
        {
            title: "Intercom and Alarm System Installation",
            description: "Intercom and Alarm Installation fortify security by enabling seamless communication and alerting occupants of potential threats, ensuring comprehensive protection for premises and occupants.",
            images: 'img005'
        },
        {
            title: "Gatemotor Installation",
            description: "Gate Motor Installation ensures seamless access control, automating gate operation for convenience and security. Professionally installed systems enhance property accessibility while bolstering overall safety and functionality.",
            images: 'img006'
        },
        {
            title: "UPS or Electricity backup",
            description: "UPS installation guarantees continuous power, preventing disruptions and data loss during electrical outages. Safeguard your electronics and maintain productivity with our professional setup services",
            images: 'ups'
        },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, []); //
    return (
        <div>
            <div>
                <div className="container py-0 mx-auto my-0">
                    <div className="relative mx-0 w-full">
                        <img
                            src={cover_img}
                            alt="A work table with house plants"
                            className="w-full h-[500px] hidden lg:block object-cover"
                        />
                        <img
                            src={cover_img}
                            alt="A work table with house plants"
                            className="hidden sm:block lg:hidden w-full h-full"
                        />
                        <img
                            src={cover_img}
                            alt="A work table with house plants"
                            className="sm:hidden w-full h-full"
                        />

                        <div
                            className="absolute z-10 top-4 sm:top-20 lg:top-32 left-0 mx-0 sm:mx-0 mt-20 sm:mt-12 sm:py-4 py-4 px-4 md:py-8 lg:py-8 xl:py-8 sm:pl-14 flex flex-col sm:justify-start items-start bg-title_bg opacity-75 text-secondary w-48 sm:w-96">
                            <h1 className="text-base sm:text-base lg:text-3xl font-semibold text-gray-800 sm:w-9/12 uppercase underline underline-offset-4">
                                Services
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-10 py-10">
                <section>
                    <h1 className="leading-5 md:leading-6 xl:leading-9 text-secondary-800 text-xl md:text-2xl xl:text-4xl font-semibold text-center uppercase text-onSurface">
                        What we do
                    </h1>
                    <p className="text-secondary-600 text-base text-center mt-4 text-onSurface">
                        We deliver your desire. Trusted for many years.
                    </p>
                </section> 

                <div className="px-10 py-10">
                <section>
                   
                <p className="text-secondary-600 text-base text-center mt-4 text-onSurface">
                Our services encompass three distinct phases. Initially, 
                we offer equipment supply, followed by installation, 
                which is essential for electronics requiring setup. 
                Finally, maintenance is provided exclusively to clients with electronic equipment. Each phase is independent, allowing clients to request only the service they require and can afford, without additional conditions. For example, some clients may opt for equipment supply alone, while others may seek maintenance exclusively, and some may require both supply and installation.
                    </p>
                </section> 
                </div>

                <div className="flex flex-wrap justify-center mt-8 gap-4">
    {services.map((service, index) => (
        <div key={index} className="max-w-sm rounded overflow-hidden shadow-lg">
            <img className="w-full h-64 object-cover" src={images[index % images.length]} alt={service.title} />
            <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">{service.title}</div>
                <p className="text-gray-700 text-base">{service.description}</p>
            </div>
        </div>
    ))}
</div>

            </div>
        </div>
    )
}

export default ServicesPage;