import React, { useState, useEffect } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import img001 from '../../assets/carousel/img001.jpeg';

const Services = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [img001];
    const services = [
        {
            title: "CCTV Installation",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vestibulum eros a arcu efficitur vehicula.",
        },
        {
            title: "Electric Fence Installation",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vestibulum eros a arcu efficitur vehicula.",
        },
        {
            title: "PC Repair",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vestibulum eros a arcu efficitur vehicula.",
        },
        {
            title: "Alarm System Installation",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vestibulum eros a arcu efficitur vehicula.",
        },
        {
            title: "Alarm System Installation",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vestibulum eros a arcu efficitur vehicula.",
        },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, []); // Run only once on component mount

    return (
        <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 text-onSurface">
            <div className="flex justify-center items-center flex-col px-4 md:px-6 xl:px-20 py-9 md:py-12 xl:py-10 mb-4">
                <section>
                    <h1 className="leading-5 md:leading-6 xl:leading-9 text-gray-800 text-xl md:text-2xl xl:text-4xl font-semibold text-center uppercase">
                    Mission Statement
                    </h1>
                </section>
            </div>
            <div className="flex flex-col lg:flex-row justify-between gap-8">
                <div className="w-full lg:w-6/10 overflow-hidden">
                    <img
                        className="w-full h-full transition duration-500 ease-in-out transform hover:scale-110"
                        src={images[currentImageIndex]}
                        alt="Security Services"
                    />
                </div>
                <div className="w-full lg:w-5/12 flex flex-col justify-center">
                    <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
                        OUR MISSION
                    </h1>
                    <p className="font-normal text-base leading-6 text-gray-600 ">
                    ThemChest Computing is determined to provide quality reliable services that will exceptionally 
                    satisfy the needs of our clients with professionalism and expertise. Our innovative concepts and 
                    advanced techniques are guaranteed to always be at the best interest of our clients. Our long term 
                    vision is to create a sustainable growth in business and job creation, as our aim is to grow into a 
                    global company that will add value in the country’s economy.

                        
                         
                    </p>
                    <section className="flex mt-8">
                        <button
                            className="px-6 h-11 text-gray-800 text-base font-medium flex items-center border border-gray-600 hover:bg-gray-200">
                            <Link to="/services" className="flex flex-row flex-nowrap items-center">
                                Explore services Offered <BsArrowRight className="ml-2" />
                            </Link>
                        </button>
                    </section>
                </div>
            </div>
            {/* <div className="flex flex-wrap justify-center mt-8 gap-4">
                {services.map((service, index) => (
                    <div key={index} className="max-w-sm rounded overflow-hidden shadow-lg">
                        <img className="w-full" src={images[index % images.length]} alt={service.title} />
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2">{service.title}</div>
                            <p className="text-gray-700 text-base">{service.description}</p>
                        </div>
                    </div>
                ))}
            </div> */}
        </div>
    );
};

export default Services;
