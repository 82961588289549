import React, { useState, useEffect } from 'react';
import img6 from '../../assets/carousel/img6.jpeg';
import v1 from '../../assets/carousel/v1.mp4';

const About = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [img6];
    // const videoUrl = 'v1.mp4'; // Replace 'your-video-url.mp4' with the URL of your video

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, []); // Run only once on component mount

    return (
        <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 text-onSurface">
            <div className="flex justify-center items-center flex-col px-4 md:px-6 xl:px-20 py-9 md:py-12 xl:py-10 mb-4">
                <section>
                    <h1 className="leading-5 md:leading-6 xl:leading-9 text-gray-800 text-xl md:text-2xl xl:text-4xl font-semibold text-center uppercase">
                        About Us
                    </h1>
                </section>
            </div>
            <div className="flex flex-col lg:flex-row justify-between gap-8">
                <div className="w-full lg:w-5/12 flex flex-col justify-center">
                    <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
                        Who we are
                    </h1>
                    <p className="font-normal text-base leading-6 text-gray-600 ">
                    We are currently amidst an era marked by the continuous introduction of increasingly advanced electronic products into the market, accompanied by a growing demand for cutting-edge technology. Electronic equipment plays a crucial role in business operations and communication. At ThemChest Computing, our approach to electronic supply services sets us apart from other suppliers due to our recent innovation strategies, which encompass not only product supply but also installations and maintenance. Our business plan underscores the promising profitability and growth potential of our supply operations.

Established in 2018 by Mr. Mayana Chester Mathe, who serves as the sole owner and director, ThemChest Computing (Pty) Ltd. specializes in supplying select electronic equipment alongside installation and maintenance services. With over 8 years of experience in the electronic equipment supply industry, Mr. Mathe has successfully served more than 50 satisfied clients, particularly in office electronics and installations. The inception of our company aimed to establish a dependable platform for the supply, installation, and maintenance of electronic equipment. ThemChest Computing proudly stands as a 100% black-owned enterprise, led solely by Mr. Mayana Chester Mathe.
                    </p>
                </div>
                <div className="w-full lg:w-8/12 relative">
                    <img
                        className="w-full h-full transition duration-500 ease-in-out"
                        src={images[currentImageIndex]}
                        alt="A group of People"
                    />
                    <video
                        className="absolute top-0 left-0 w-full h-full object-cover"
                        src={images}
                    />
                </div>
            </div>
        </div>
    );
};

export default About;
